.display--main--wrapper {
    display: flex;
    flex-direction: column;
    width: 98vw;
    height: 50vh;
    margin: 20vh 0 0 1vw;
    padding: 4vh 0 10vh;
    background: rgb(241,244,253);
    background: radial-gradient(circle, rgba(241,244,253,1) 0%, rgba(248,250,254,1) 38%, rgba(255,255,255,1) 50%, rgba(250,251,254,1) 62%, rgba(241,244,253,1) 100%); 
}

.display--carousel--wrapper {
    margin-left: .5vw;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 3) - 1vw);
    overflow-x: auto;
    scrollbar-width: none;
}

.display--title {
    margin: 0 0 3vh 5vw;
    font-size: 2.5vmax;
    text-decoration: none;
}

.display--title h3 {
    margin: 0;
}

.display--card--wrapper {
    width: 30vw;
    height: 40vh;
    margin: 0 1vw 0 0;
    overflow: hidden;
    position: relative;
}

.display--card--img {
    z-index: 1;
    object-fit: cover;
    object-position: 50% 82.5%;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

 .display--card--img:hover {
    opacity: 0.7
 }

 .display--card--title {
    margin: 0;
    z-index: 3;
    color: rgb(255, 255, 255);
    font-size: 3em;
}

.display--card--title--wrapper {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 16vw;
    height: 5vh;
    text-align: center;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3.5px);
}

