.carousel--wrapper {
    width: 97%;
    margin: 5vh 0 0 1.2vw;
    padding-left: 10px;
    position: relative;
    display: flex;
    background: rgb(242, 245, 253);
    background: radial-gradient(circle, rgba(242, 245, 253, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(248, 252, 255, 1) 58%, rgba(241, 244, 253, 1) 94%);
}

.carousel--wrapper i {
    top: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    background-color: #ffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.23);
    transform: translateY(-50%);
    transition: transform 0.1s linear;
}

.carousel--wrapper i:active {
    transform: translateY(-50%) scale(0.85);
}

.carousel--wrapper i:first-child {
    left: -22px;
}

.carousel--wrapper i:last-child {
    right: -22px;
}

.carousel--wrapper .carousel {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 6) - 28px);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    border-radius: 8px;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
    display: none;
}

.carousel.no-transition {
    scroll-behavior: auto;
}

.carousel.dragging {
    scroll-snap-type: none;
    scroll-behavior: auto;
}

.carousel.dragging .carousel--card {
    cursor: grab;
    user-select: none;
}

.carousel .carousel--card {
    scroll-snap-align: start;
    height: 27.5vh;
    width: 20vw;
    list-style: none;
    cursor: pointer;
    border-radius: 8px;
}

.carousel--img {
    width: 13vw;
    height: 27vh;
    border-radius: 1.2rem;
    object-fit: cover;
}


@media screen and (max-width: 900px) {
    .wrapper .carousel {
        grid-auto-columns: calc((100% / 2) - 9px);
    }
}

@media screen and (max-width: 600px) {
    .wrapper .carousel {
        grid-auto-columns: 100%;
    }
}

.no-transition {
    transition: none !important;
}

.dragging {
    cursor: grabbing;
    user-select: none;
}