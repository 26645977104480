.mainsection--wrapper {
    display: flex;
    flex-direction: column;
    height: 90vh;
    width: 100vw;
    margin: 10vh 0 0 0;
    padding-bottom: 5vh;
    background: rgb(242,245,253);
    background: linear-gradient(90deg, rgb(242,245,253) 0%, rgba(255,255,255,1) 7%, rgba(248,252,255,1) 50%, rgba(241,244,253,1) 100%); 
}

.mainsection--img {
    width: 40vw;
    height: 80vh;
    border-radius: 1em;
}

.mainsection--text--wrapper {
    margin: 10vh 0 0 20vw;
}

.mainsection--underimg--wrapper {
    margin: 1vh 0 0 6vw;
}

.mainsection--underimg {
    width: 5vw;
    height: 100%;
    margin-right: .6vw;
    border-radius: 2px;
    cursor: pointer;
}

.mainsection--main {
    display: flex;
    justify-content: start;
    margin: 2vh 0 0 5vw
}