.header--img {
    width: 100vw;
    height: 72.5vh;
    margin: 0 0 10px;

    opacity: 0.95;
    z-index: 1;
    display: block;
}

.header--logo {
    position: absolute;
    z-index: 3;
    top: 19vh;
    left: 37.5vw;
    width: 20vw;
    height: 33vh;
}

.header--button--wrapper {
    display: flex;
    position: absolute;
    z-index: 2;
    right: 0;
    margin-right: 2.5vw;
}

.header--buttons {
    color: white;
    font-size: 1rem;
    border-bottom-left-radius: 10px;
    padding-left: 2.5vw;
}

.header--buttons:hover {
    color: rgb(245, 235, 235);
    font-size: .99rem;
}

a {
    text-decoration: none;
}
